/* eslint-disable prefer-template */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import QRCode from 'qrcode.react';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import Button from 'spotii-ui/Button';

const QRCodeModal = ({ show, setShowQRCodeModal, employeeData }) => {
  const { t } = useTranslation();
  const { currentUser, currency } = useSelector(state => state.application);

  const [qrCodeText, setQrCodeText] = useState('');

  const qrRef = useRef(null)

  const tag = 'spotii-merchant-qr';
  const { merchant } = currentUser;

  useEffect(() => {
    const qrData = {
      tag,
      id: merchant.merchantId,
      display_name: merchant.legalBusinessName,
      address: `${merchant.line1 ?? ''}${merchant.line2 ? ' ' + merchant.line2 : ''}${merchant.line3 ? ' ' + merchant.line3 : ''}, ${merchant.city}, ${merchant.country}`.trim(),
      employee_id: employeeData.api_employee_id,
      employeeName: `${employeeData.first_name ?? ''} ${employeeData.last_name ?? ''}`.trim(),
      currency,
    }
    setQrCodeText(JSON.stringify(qrData))
  }, [employeeData, merchant, currency])

  const handleClose = () => {
    setShowQRCodeModal(false);
  };

  const handleCaptureClick = async () => {
    const canvas = await html2canvas(qrRef.current);
    const dataURL = canvas.toDataURL('image/png');
    downloadjs(dataURL, `${employeeData.first_name ?? ''} ${employeeData.last_name ?? ''}.png`.trim(), 'image/png');
  };

  return (
    <Modal
      style={{ backdropFilter: 'blur(5px)' }}
      size="lg"
      show={show}
      onHide={handleClose}
      centered
    >
      <Modal.Header style={{ border: 'none', height: 0 }} closeButton />
      <Modal.Body style={{ alignSelf: 'center', margin: '100px 0', }}>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p style={{ fontWeight: 'bold', fontSize: '20px' }}>{t('paymentQrCode')}</p>
            <div ref={qrRef} className='w-100'>
              <QRCode
                ref={qrRef}
                value={qrCodeText}
                renderAs="svg"
                style={{
                  width: '100%',
                  height: 'fit-content',
                }}
              />
            </div>
            {/* <div className="pb-1">
              <span className="font-weight-bold pb-1">Date created: </span>
              {moment.parseZone(qrData.createdAt).format('DD MMMM YYYY')}
            </div> */}
            <div className='mt-3'>
              <span className="font-weight-bold">Reference: </span>
              {`${employeeData.first_name ?? ''} ${employeeData.last_name ?? ''}`}
            </div>
            <Button onClick={handleCaptureClick} className='mt-3' type='primary'>Download QR</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

QRCodeModal.propTypes = {
  setShowQRCodeModal: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  employeeData: PropTypes.shape({
    api_employee_id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
  // handleSendLink: PropTypes.func.isRequired,
  // copyTextToClipboard: PropTypes.func.isRequired,
};

export default QRCodeModal;
