import React from 'react';
import PropTypes from 'prop-types';
import NavItem from 'components/NavItem/NavItem';
import Icon from 'spotii-ui/Icon';
import authApi from 'api/auth';
import './SidebarNav.scss';
import { useTranslation } from 'react-i18next';

const SidebarNav = ({ items }) => {
  const { t } = useTranslation();
  return (
    <nav className="sidebar-nav">
      <ul>
        {items.map(item => (
          <li key={item.pathname}>
            <NavItem className="sidebar-nav__link" {...item} />
          </li>
        ))}
        <li className="d-block d-xl-none">
          <div
            className="sidebar-nav__link"
            role="button"
            tabIndex="0"
            onClick={() => authApi.revoke()}
            onKeyPress={e => e.key === 'Enter' && authApi.revoke()}
          >
            <Icon type="log-out" />
            {t('logOut')}
          </div>
        </li>
      </ul>
    </nav>
  );
};

SidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(NavItem.propTypes)).isRequired,
};

export default SidebarNav;
