import React, { useEffect, useState } from 'react';
import Heading from 'spotii-ui/Heading';
import Button from 'spotii-ui/Button';
import { LottieSuccess } from 'spotii-ui/Lottie';
import Layout from 'auth/Layout';
import TopBar from 'auth/TopBar';
import Paper from 'auth/Paper';
import { useTranslation } from 'react-i18next';
import BlockHead from 'auth/BlockHead';

export default function OrderApproved() {
  const [paused, setPaused] = useState(true);
  const { t } = useTranslation();
  // Start plaing animation after a while
  useEffect(() => {
    const timerAnimation = setTimeout(() => {
      setPaused(false);
    }, 300);
    return () => {
      clearTimeout(timerAnimation);
    };
  });

  return (
    <Layout>
      <TopBar />
      <Layout.Container>
        <Paper size="tiny">
          <LottieSuccess width={240} height={240} isStopped={paused} />
          <BlockHead type="under">
            <Heading h1>{t('allDone')}</Heading>
            <p>{t('welcomeToSpotii')}</p>
            <p>
              <Button href="/" size="large" type="primary" block>
                {t('getStarted')}
              </Button>
            </p>
          </BlockHead>
        </Paper>
      </Layout.Container>
    </Layout>
  );
}
