import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Icon from 'spotii-ui/Icon';
import b from 'b_';
import { Row, Col, ListGroup, Modal } from 'react-bootstrap';
import Paper from 'components/Paper';
import LabelValue from 'components/LabelValue';
import countries, { shortenCountryName } from 'utils/countries';
import Form, { FormRow, InputRow } from 'spotii-ui/Form';
import message from 'spotii-ui/Message';
import { useFormik } from 'formik';
import Button from 'spotii-ui/Button';
import * as Yup from 'yup';

// Type
import { AppStateType, editSettlementEmails } from 'reducers/application';

const Company = ({ appState }) => {
  const { currentUser, settlementEmails } = appState;
  const {
    legalBusinessName,
    licenseNumber,
    licenseExpiredAt,
    trnNumber,
    line1,
    line2,
    city,
    country,
    postcode,
  } = currentUser.merchant;

  const addressLine1 = [line1, line2].join(' ');
  const addressLine2 = [
    city,
    country ? shortenCountryName(countries.getName(country, 'en')) : null,
  ].join(', ');
  const cls = b.with('settings');
  const { t } = useTranslation();
  const currLang = useSelector(state => state.language.language);
  const dispatch = useDispatch();
  const [deleteRecipientVisible, setDeleteRecipientVisible] = useState(false);
  const [deleteRecipientLoading, setDeleteRecipientLoading] = useState(false);
  const [recipientToDelete, setRecipientToDelete] = useState(null);

  const handleCancelDeleteRecipient = () => {
    setRecipientToDelete(null);
    setDeleteRecipientLoading(false);
    setDeleteRecipientVisible(false);
  };

  const handleConfirmDeleteRecipient = async () => {
    setDeleteRecipientLoading(true);
    try {
      dispatch(editSettlementEmails({ email: recipientToDelete, isDelete: true }));
      message.success(t('recipientSuccessfullyRemoved'));
    } catch (e) {
      message.error(t('somethingWentWrong'));
      console.error(e);
    }

    handleCancelDeleteRecipient();
  };

  const handleDeleteRecipient = email => {
    setRecipientToDelete(email);
    setDeleteRecipientVisible(true);
  };

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(t('somethingWrongEmail'))
        .required(t('emailIsRequired')),
    }),
    onSubmit: async ({ email }, actions) => {
      try {
        dispatch(editSettlementEmails({ email, isDelete: false }));
        message.success(t('recipientSuccessfullyAdded'));
        actions.setStatus('success');
      } catch (e) {
        message.error(t('somethingWentWrong'));
        console.error(e);
      }
      actions.resetForm();
    },
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    isSubmitting,
    handleChange,
    handleSubmit,
    dirty,
  } = formik;

  const getError = key => touched[key] && errors[key];

  return (
    <Paper>
      <Row className={cls('section')}>
        <Col md={9}>
          <p className={cls(`paragraph-${currLang}`)}>
            {t('ifBusinessChangesContact')}{' '}
            <a href="mailto:support@nymcard.com">support@nymcard.com</a>
          </p>
        </Col>
      </Row>
      <Row className={cls('section')}>
        <Col md="4" className={cls('section-label')}>
          {t('businessDetails')}
        </Col>
        <Col md="5">
          <LabelValue label={t('legalName')}>{legalBusinessName}</LabelValue>
          <LabelValue label={t('licenseNumber')}>{licenseNumber || '—'}</LabelValue>
          <LabelValue label={t('licenseExpirationDate')}>{licenseExpiredAt || '—'}</LabelValue>
          <LabelValue label={t('taxNumber')}>{trnNumber || '—'}</LabelValue>
        </Col>
      </Row>
      <Row className={cls('section')}>
        <Col md="4" className={cls('section-label')}>
          {t('businessAddress')}
        </Col>
        <Col md="5">
          <LabelValue label="">
            <div>
              <p className={cls(`address-paragraph-${currLang}`)}>{addressLine1}</p>
              <p className={cls(`address-paragraph-${currLang}`)}>{addressLine2}</p>
              <p className={cls(`address-paragraph-${currLang}`)}>{postcode}</p>
            </div>
          </LabelValue>
        </Col>
      </Row>
      <Row className={cls('section')}>
        <Col md="4" className={cls('section-label')}>
          {t('settlementReportRecipients')}
        </Col>
        <Col md="5">
          {settlementEmails.length ? (
            <ListGroup>
              {settlementEmails.map(email => (
                <ListGroup.Item key={email} className={cls('email-list-item')}>
                  {email}
                  <div
                    role="button"
                    tabIndex={0}
                    className={cls('clickable')}
                    onClick={() => handleDeleteRecipient(email)}
                    onKeyDown={() => handleDeleteRecipient(email)}
                  >
                    <Icon type="times-circle" />
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <div>
              <p className={cls(`address-paragraph-${currLang}`)}>{t('recipientsListDesc')}</p>
            </div>
          )}

          <div className={cls('email-form-container')}>
            <Form onSubmit={handleSubmit}>
              <InputRow
                name="email"
                label={t('addEmail')}
                type="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={getError('email')}
              />
              <FormRow type="submit">
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={isSubmitting}
                  disabled={!dirty || errors.email || isSubmitting}
                >
                  {t('submit')}
                </Button>
              </FormRow>
            </Form>

            <Modal show={deleteRecipientVisible} onHide={handleCancelDeleteRecipient} centered>
              <Modal.Body>
                <div className="text_align_center">
                  <p className="color-black40 mb-40">
                    {t('removeRecipientConfirmDesc', {
                      email: recipientToDelete,
                    })}
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="primary"
                  loading={deleteRecipientLoading}
                  disabled={deleteRecipientLoading}
                  onClick={handleConfirmDeleteRecipient}
                >
                  {t('confirm')}
                </Button>

                <Button
                  type="default"
                  disabled={deleteRecipientLoading}
                  onClick={handleCancelDeleteRecipient}
                >
                  {t('cancel')}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Col>
      </Row>
    </Paper>
  );
};

Company.propTypes = {
  appState: PropTypes.shape(AppStateType).isRequired,
};

export default Company;
