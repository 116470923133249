import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import b from 'b_';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Icon from 'spotii-ui/Icon';
import Paper from 'components/Paper';
import BankAccountsList from 'components/BankAccountsList';

// Type
import { fetchBankAccounts } from 'reducers/bankAccounts';

const BankAccount = () => {
  const { t } = useTranslation();
  const cls = b.with('settings');
  const dispatch = useDispatch();
  const bankAccounts = useSelector(state => state.bankAccounts);
  const currLang = useSelector(state => state.language.language);

  useEffect(() => {
    dispatch(fetchBankAccounts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper>
      <Row className={cls('section')}>
        <Col md={9}>
          <p className={cls(`paragraph-${currLang}`)}>{t('bankAccountDesc')}</p>
        </Col>
      </Row>
      <Row className={cls('section')}>
        <Col md="4" className={cls('section-label')}>
          {t('bankAccounts')}
        </Col>
        <Col md="5">
          {bankAccounts.isResolved ? (
            <>
              <BankAccountsList list={bankAccounts.list} />
              {/* <Button to="/settings/add-bank-account" size="small" htmlType="button" block>
                {t('addNewBankAccount')}
              </Button> */}
            </>
          ) : (
            <Icon type="loading" />
          )}
        </Col>
      </Row>
    </Paper>
  );
};

export default BankAccount;
