import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Paper from 'components/Paper';
import Icon from 'spotii-ui/Icon';
import CreateOrderForm from 'components/CreateOrderForm';
import BlockHead from 'auth/BlockHead';

const AddNewOrderForm = ({ appState }) => {
  const { t } = useTranslation();
  const currLang = useSelector(state => state.language.language);
  const [success, setSuccess] = useState(false);
  const userHasRole = appState.loggedEmployee.role;
  let userHasCheckoutReadPerm = true;
  if (userHasRole) {
    const userPermissions = appState.loggedEmployee.role.permissions;
    userHasCheckoutReadPerm = userPermissions.includes('checkouts_read');
  }

  if (success) {
    if (userHasCheckoutReadPerm) {
      return <Redirect to="/checkout/checkouts" push />;
    }
  }

  return (
    <div className="checkout__container-small">
      <Paper>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {userHasCheckoutReadPerm && (
            <p style={{ marginBottom: '35px', display: 'flex' }}>
              <Link to="/checkout/checkouts">
                {currLang === 'ar' ? t('back') : ''}
                <Icon type="chevron-left" />
                {currLang !== 'ar' ? t('back') : ''}
              </Link>
            </p>
          )}
          <div style={{ alignSelf: 'center' }}>
            <BlockHead type="normal">
              <p style={{ display: 'flex' }}>{t('paymentLinkSub')}</p>
            </BlockHead>
            <CreateOrderForm
              appState={appState}
              onSuccess={() => {
                setSuccess(true);
              }}
            />
          </div>
        </div>
      </Paper>
    </div>
  );
};
AddNewOrderForm.propTypes = {
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AddNewOrderForm;
