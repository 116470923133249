import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'reducers/language';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import b from 'b_';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Paper from 'components/Paper';
import ChangePassword from 'components/ChangePassword';
import LabelValue from 'components/LabelValue';

// Type
import { AppStateType } from 'reducers/application';

const Company = ({ appState }) => {
  const { currentUser } = appState;
  const { firstName, lastName, email } = currentUser;
  const cls = b.with('settings');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [slectedLang, setLang] = useState('en');
  const langOptions = [
    { label: 'English', value: 'en' },
    { label: 'عربى', value: 'ar' },
  ];

  return (
    <Paper>
      <Row className={cls('section', { spacing: 'large' })}>
        <Col md="4" className={cls('section-label')}>
          {t('personalDetails')}
        </Col>
        <Col md="5">
          <LabelValue label={t('firstName')}>{firstName || '-'}</LabelValue>
          <LabelValue label={t('lastName')}>{lastName || '-'}</LabelValue>
          <LabelValue label={t('businessEmail')}>{email}</LabelValue>
          <LabelValue label={t('setLanguage')}>
            <Select
              className={cls('language-selector')}
              options={langOptions}
              placeholder={t('language')}
              label={t('language')}
              onChange={e => {
                setLang(e.value);
                dispatch(changeLanguage({ lang: e.value }));
              }}
            />
          </LabelValue>
        </Col>
      </Row>
      <Row className={cls('section')}>
        <Col md="4" className={cls('section-label')}>
          {t('changePassword')}
        </Col>
        <Col md="5">
          <ChangePassword />
        </Col>
      </Row>
    </Paper>
  );
};

Company.propTypes = {
  appState: PropTypes.shape(AppStateType).isRequired,
};

export default Company;
