import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { Link } from 'react-router-dom';
import { SpotiiZipLogo } from 'spotii-ui/Logo';
import Icon from 'spotii-ui/Icon';
import config from 'utils/config';
import { LocationType } from 'utils/types';
import Button from 'spotii-ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from 'reducers/language';
import { useTranslation } from 'react-i18next';
import locales from './locales';
import './TopBar.scss';

// TODO: translate "skip" during sign up process
const TopBar = ({ authOption, skipTo, skip }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currLang = useSelector(state => state.language.language);
  const cls = b.with('top-bar');
  const { message, link, linkTitle } = locales[authOption] || {};

  return (
    <div className={cls()}>
      <a href={config('root_website')} className={cls('logo')}>
        <SpotiiZipLogo />
      </a>
      <div className={cls('lang-selector-container')}>
        <div className={cls(currLang === 'ar' ? 'lang-selector-ar' : 'lang-selector')}>
          <Button
            type="primary"
            size="small"
            htmlType="button"
            shape="normal"
            onClick={() => dispatch(changeLanguage({ lang: currLang === 'ar' ? 'en' : 'ar' }))}
          >
            {currLang === 'ar' ? 'English' : 'عربى'}
          </Button>
        </div>
        {authOption ? (
          <div className={cls(currLang === 'ar' ? 'auth-option-ar' : 'auth-option')}>
            <span className="d-none d-sm-inline">{t(message)}</span>
            <Link className="p-0 mr-2" to={link}>{t(linkTitle)}</Link>
          </div>
        ) : null}
        {skipTo ? (
          <div className={cls(currLang === 'ar' ? 'skip-ar' : 'skip')}>
            <Link to={skipTo}>
              {skip}
              <Icon type="chevron-right" />
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
};

TopBar.propTypes = {
  authOption: PropTypes.oneOf(['login', 'register', 'logout']),
  skip: PropTypes.string,
  skipTo: LocationType,
};

TopBar.defaultProps = {
  authOption: null,
  skip: null,
  skipTo: null,
};

export default TopBar;
